import React from 'react';
import { Modal, Button, Empty } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { SessionVrUser } from 'shared/types';
import { UserField } from './components';
import Text from 'antd/lib/typography/Text';

type Props = {
  usersList: SessionVrUser[];
  sessionId?: string;
  internalId?: string;
};

const AttendeesModal: React.FC<Props> = ({ usersList, sessionId, internalId }): JSX.Element => {
  const dispatch = useAppDispatch();
  const isModalOpened = useAppSelector((state): boolean => state.modal.isModalOpened);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      visible={isModalOpened}
      title='Session details'
      onCancel={handleCancel}
      footer={[
        <Button key='cancel' type={'primary'} onClick={handleCancel}>
          Close
        </Button>
      ]}
      width={'1000px'}
    >
      {!!internalId && !!sessionId ? (
        <Text strong>
          <p>{`Student Session Id: ${internalId}`}</p>
          <p>{`System Session Id: ${sessionId}`}</p>
        </Text>
      ) : (
        ''
      )}
      {!!usersList.length ? (
        usersList.map((user): JSX.Element => <UserField user={user} key={user.id} />)
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description='No users were found for this session'
        />
      )}
    </Modal>
  );
};

export default AttendeesModal;
