import { AxiosResponse } from 'axios';
import ApiService from './api';
import {
  School,
  NewSchool,
  SchoolFileUpdate,
  TableSchoolFile,
  SchoolFileRemove,
  NewSchoolUpdate
} from 'shared/types';

export const getSchools = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string }
): Promise<AxiosResponse<School[]>> =>
  await ApiService.get<School[]>('school', { page, limit, ...orderBy });

export const getSchoolsList = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string }
): Promise<AxiosResponse<School[]>> =>
  await ApiService.get<School[]>('school/list', { page, limit, ...orderBy });

export const deleteSchool = async (id: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete<void>(`school?id=${id}`);

export const addSchool = async (newSchool: NewSchool): Promise<AxiosResponse<School>> =>
  await ApiService.post<NewSchool, School>('school', newSchool);

export const updateSchool = async (
  schoolId: string,
  newSchool: NewSchoolUpdate
): Promise<AxiosResponse<void>> =>
  await ApiService.patch<NewSchoolUpdate, void>(`school/${schoolId}`, newSchool);

export const getSchoolFiles = async (
  schoolId: string
): Promise<AxiosResponse<TableSchoolFile[]>> => {
  return await ApiService.get<TableSchoolFile[]>(`school/files/${schoolId}`);
};

export const uploadSchoolFile = async (
  schoolId: string,
  file: File
): Promise<AxiosResponse<void>> => {
  const bodyFormData = new FormData();
  bodyFormData.append('media', file);
  return await ApiService.post<FormData, void>(`school/files/${schoolId}`, bodyFormData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const renameSchoolFile = async (
  schoolId: string,
  oldFileKey: string,
  newFileKey: string
): Promise<AxiosResponse<void>> => {
  return await ApiService.patch<SchoolFileUpdate, void>(`school/files/${schoolId}`, {
    oldFileKey,
    newFileKey
  });
};

export const removeSchoolFile = async (
  schoolId: string,
  fileKey: string
): Promise<AxiosResponse<void>> => {
  return await ApiService.delete<SchoolFileRemove, AxiosResponse<void>>(
    `school/files/${schoolId}`,
    {
      fileKey
    }
  );
};
