import { createAction, createReducer } from '@reduxjs/toolkit';
import { Admin, AuthState } from 'shared/types';

const initialState: AuthState = {
  isAuth: false,
  user: null
};

// Actions

export const loginUserSuccess = createAction<Admin | null>('auth/loginUserSuccess');
export const loginUserFail = createAction('auth/loginUserFail');
export const logoutUser = createAction('auth/logout');
export const updateUser = createAction<Admin>('auth/updateUser');

// Reducer

export const authReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(loginUserSuccess, (state, action): void => {
      state.isAuth = true;
      state.user = action.payload;
    })

    .addCase(loginUserFail, (state): void => {
      state.isAuth = false;
      state.user = null;
    })

    .addCase(logoutUser, (state): void => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      state.isAuth = false;
      state.user = null;
    })

    .addCase(updateUser, (state, action): void => {
      state.user = action.payload;
    });
});
