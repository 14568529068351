import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  from {
    opacity: 0.9;
  }
  to {
    opacity: 0.1;
  }
`;

export const Indicator = styled.span`
  width: 7px;
  height: 7px;
  background: #06c906;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  animation: ${blink} 0.6s cubic-bezier(0.5, 0, 1, 1) infinite alternate;
`;
