import { useCallback, useState } from 'react';
import {
  EResponseHeaders,
  ESnackbarStyle,
  Log,
  ReportFormValues,
  VrUserInternal
} from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getLogs } from 'services/api/logsService';
import { useDebounce, useSchoolParams } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { getSortQueryParams } from 'utils/sort-utils';
import { searchVrUsers } from 'services/api/vrUsersService';

type Result = {
  logs: Log[];
  fetchLogs: () => Promise<void>;
  fetchVrUsers: () => Promise<void>;
  totalPages: number;
  totalLogs: number;
  isLogsLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  setFormValues: (params: ReportFormValues) => void;
  formValues: ReportFormValues;
  clearValues: () => void;
  vrUsers: VrUserInternal[];
};

const useLogsData = (): Result => {
  const INITIAL_VALUES = {
    startedAtStart: '',
    startedAtEnd: '',
    search: '',
    userId: '',
    userIds: '',
    schoolId: '',
    finishedAtStart: '',
    finishedAtEnd: '',
    minDuration: '',
    maxDuration: '',
    internalId: ''
  };
  const [logs, setLogs] = useState<Log[]>([]);
  const [vrUsers, setVrUsers] = useState<VrUserInternal[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalLogs, setTotalLogs] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [isLogsLoading, setIsLogsLoading] = useState<boolean>(true);
  const { getSchoolParams } = useSchoolParams();
  const [formValues, setFormValues] = useState<ReportFormValues>(INITIAL_VALUES);

  const debouncedParams = useDebounce(formValues, 1000);

  const fetchLogs = useCallback(async (): Promise<void> => {
    setIsLogsLoading(true);
    try {
      const orderBy = { orderBy: sortBy || 'startedAt,DESC' };
      const response = await getLogs(currentPage, PAGE_SIZE, orderBy, {
        ...getSortQueryParams(debouncedParams),
        ...getSchoolParams()
      });
      setLogs(response.data);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalLogs(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
      if (+response.headers[EResponseHeaders.TOTAL_RECORDS] <= PAGE_SIZE) setCurrentPage(1);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsLogsLoading(false);
    }
  }, [currentPage, sortBy, getSchoolParams, debouncedParams]);

  const fetchVrUsers = useCallback(async (): Promise<void> => {
    try {
      const response = await searchVrUsers(
        {},
        {
          ...getSortQueryParams(debouncedParams),
          ...getSchoolParams()
        }
      );
      setVrUsers(response.data);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    }
  }, [debouncedParams, getSchoolParams]);

  const clearValues = (): void => setFormValues(INITIAL_VALUES);

  return {
    logs,
    fetchLogs,
    totalPages,
    totalLogs,
    isLogsLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    setFormValues,
    formValues,
    clearValues,
    vrUsers,
    fetchVrUsers
  };
};

export default useLogsData;
