import React from 'react';
import { Select } from 'antd';
import { SelectContainer } from 'shared/styles';
import { useAppSelector } from 'shared/hooks';
import { Admin, EAdminRoles, School } from 'shared/types';

type Props = {
  value: string;
  handleSchoolChange: (schoolId: string) => void;
  disabled: boolean;
  hideAllSchools?: boolean;
};

const SchoolSelect: React.FC<Props> = ({
  value,
  handleSchoolChange,
  disabled,
  hideAllSchools
}): JSX.Element => {
  const currentUser = useAppSelector((state): Admin | null => state.auth.user);
  const schools = useAppSelector((state): School[] => state.schools.allSchools);

  if (currentUser?.role !== EAdminRoles.SUPER_ADMIN) return <div />;

  return (
    <SelectContainer>
      <Select
        style={{ width: '100%' }}
        onChange={handleSchoolChange}
        placeholder={'School'}
        value={value}
        disabled={disabled}
      >
        {!hideAllSchools && (
          <Select.Option disabled={disabled} value={''}>
            {'All schools (default)'}
          </Select.Option>
        )}
        {schools.map(
          (school): JSX.Element => (
            <Select.Option disabled={disabled} key={school.id} value={school.id}>
              {school.Name}
            </Select.Option>
          )
        )}
      </Select>
    </SelectContainer>
  );
};

export default SchoolSelect;
