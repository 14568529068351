import styled from 'styled-components';

export const UserField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const UserInfo = styled.div<{ isFromAnotherSchool: boolean }>`
  display: flex;
  margin: 5px 0;
  font-size: 16px;
  color: ${({ isFromAnotherSchool }): string => (isFromAnotherSchool ? 'red' : 'black')};

  & span:first-child {
    font-weight: bold;
  }
`;
