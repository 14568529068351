import React, { ReactNode } from 'react';
import { Container } from './styles';

type Props = {
  children: ReactNode;
};

const ContentContainer: React.FC<Props> = ({ children }): JSX.Element => {
  return <Container>{children}</Container>;
};

export default ContentContainer;
