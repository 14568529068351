import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { DatePeriod } from 'shared/types';

type Props = {
  values: DatePeriod;
  setDates: (dates: DatePeriod) => void;
  isBlock?: boolean;
};

const DateRange: React.FC<Props> = ({ values, setDates, isBlock }): JSX.Element => {
  const { RangePicker } = DatePicker;
  const { startedAtStart, startedAtEnd } = values;
  const START = startedAtStart ? moment(startedAtStart) : null;
  const END = startedAtEnd ? moment(startedAtEnd).subtract(1, 'd') : null;

  const handleDateChange = (date: string[]): void => {
    const startedAtStart = moment(date[0]).add(2, 'h').toISOString();
    const startedAtEnd = moment(date[1]).add(1, 'd').add(2, 'h').toISOString();
    setDates({ startedAtStart, startedAtEnd });
  };

  return (
    <>
      <RangePicker
        onCalendarChange={(...rest): void => handleDateChange(rest[1])}
        value={[START, END]}
        ranges={{
          Today: [moment(), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')]
        }}
        inputReadOnly={true}
        size='small'
        style={{ width: isBlock ? '180px' : '270px', display: isBlock ? 'block' : '' }}
      />
    </>
  );
};

export default DateRange;
