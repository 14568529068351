import { AxiosResponse } from 'axios';
import ApiService from './api';
import {
  FirebaseIdsRange,
  VrUser,
  CreatedVrUser,
  UpdatedVrUser,
  QueryParams,
  VrUserInternal
} from 'shared/types';

export const getVrUsers = async (
  idsRange: FirebaseIdsRange | {},
  page: number,
  limit: number,
  orderBy: { orderBy?: string },
  search: { search?: string }
): Promise<AxiosResponse<VrUser[]>> => {
  return await ApiService.get<VrUser[]>('vr-users', {
    ...idsRange,
    page,
    limit,
    ...orderBy,
    ...search
  });
};

export const searchVrUsers = async (
  orderBy: { orderBy?: string },
  queryParams: QueryParams
): Promise<AxiosResponse<VrUserInternal[]>> => {
  return await ApiService.get<VrUserInternal[]>('vr-users/all-internal', {
    orderBy,
    ...queryParams
  });
};

export const getUsersByFirebaseIdRange = async (
  idsRange: FirebaseIdsRange
): Promise<AxiosResponse<VrUser[]>> => {
  return await ApiService.get<VrUser[]>('vr-users', {
    ...idsRange,
    page: 1,
    limit: 1000
  });
};

export const getUsersByFirebaseId = async (id: string): Promise<AxiosResponse<VrUserInternal>> => {
  return await ApiService.get<VrUserInternal>(`vr-users/firebase/${id}`);
};

export const addVrUser = async (vrUser: CreatedVrUser): Promise<AxiosResponse<VrUser>> =>
  await ApiService.post<CreatedVrUser, VrUser>('vr-users', vrUser);

export const updateVrUser = async (
  id: string,
  vrUser: UpdatedVrUser
): Promise<AxiosResponse<VrUser>> =>
  await ApiService.patch<UpdatedVrUser, VrUser>(`vr-users/${id}`, vrUser);

export const deleteVrUser = async (id: string): Promise<void> =>
  await ApiService.delete<{ vrUsers: string[] }, void>('vr-users', { vrUsers: [id] });
