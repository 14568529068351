import { Log, School } from '.';

export enum EStreamStatus {
  records = 'records',
  live = 'live'
}

export type SessionVrUser = {
  id: string;
  firebaseId: string;
  firstName: string;
  lastName: string;
  email: string;
  logs: Log[];
};

export type Session = {
  roomId: string;
  duration: number;
  logId: string[];
  startedAt: string;
  sessionId: string;
  usersQuantity: number;
  users: SessionVrUser[];
  school: School;
  timezone: string;
  streamId?: string;
  streamUrl?: string;
  streamStatus?: EStreamStatus;
  InternalId?: string;
};

export type TableSession = {
  key: string;
  id: string;
  roomId: string;
  startedAt: string;
  duration: string;
  users: SessionVrUser[];
  userName: string;
  school: string;
  streamUrl?: string;
  streamStatus?: EStreamStatus;
  InternalId?: string;
};
