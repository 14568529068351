import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Session, QueryParams } from 'shared/types';

export const getSessions = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string },
  queryParams: QueryParams
): Promise<AxiosResponse<Session[]>> =>
  await ApiService.get<Session[]>('sessions', { page, limit, ...orderBy, ...queryParams });

export const deleteSession = async (sessionId: string): Promise<AxiosResponse<void>> =>
  await ApiService.delete<void>(`sessions/${sessionId}`);
