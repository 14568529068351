import React, { useEffect, useState } from 'react';
import { Admin, EAdminRoles, ELogsUserRoles, SessionVrUser, TableLog } from 'shared/types';
import * as Styled from './styles';
import { DATE_FORMAT, parseISODateFormat, secondsTransform } from 'utils/date-utils';
import { Table } from 'antd';
import { LOG_COLUMNS } from './constants';
import { useAppSelector } from 'shared/hooks';
import { TableWrapper } from 'shared/styles';
import { getUser } from 'utils/user-utils';

type Props = {
  user: SessionVrUser;
};

const UserField: React.FC<Props> = ({ user }): JSX.Element => {
  const [tableLogs, setTableLogs] = useState<TableLog[]>([]);
  const currentUser = useAppSelector((state): Admin => state.auth.user!);

  const checkIsUserFromAnotherSchool = (): boolean => {
    if (currentUser.role === EAdminRoles.SUPER_ADMIN) return false;
    const email = user.email;
    // @ts-ignore
    const candidate = currentUser.school?.domain || '';
    return !(email && email.includes(candidate));
  };

  useEffect((): void => {
    const modifiedLogs = user.logs.map((item): TableLog => {
      const { Guid, userRole, userId, user, school, roomId, duration, finishedAt, startedAt } =
        item;
      return {
        key: Guid,
        guid: Guid,
        user: user?.firstName || userId,
        roomId: roomId || '',
        startedAt: parseISODateFormat(startedAt, DATE_FORMAT),
        finishedAt: parseISODateFormat(finishedAt, DATE_FORMAT),
        duration: secondsTransform(duration),
        userRole: ELogsUserRoles[userRole],
        school: school?.name || ''
      };
    });
    setTableLogs(modifiedLogs);
  }, [user]);

  return (
    <Styled.UserField>
      <Styled.UserInfo isFromAnotherSchool={checkIsUserFromAnotherSchool()}>
        <span>{getUser(user)}</span>
      </Styled.UserInfo>
      <TableWrapper>
        <Table columns={LOG_COLUMNS} dataSource={tableLogs} pagination={false} size={'middle'} />
      </TableWrapper>
    </Styled.UserField>
  );
};

export default UserField;
