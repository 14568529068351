import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';

export const SidebarContainer = styled.div<{ isSidebarOpened: boolean }>`
  width: 256px;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  z-index: 999;
  border-right: 1px solid #f0f0f0;
  background: #fff;
  transition: all 0.3s;

  @media ${DEVICE.tabletLarge} {
    transform: ${({ isSidebarOpened }): string =>
      isSidebarOpened ? 'translateX(0%)' : 'translateX(-100%)'};
  }

  @media ${DEVICE.tablet} {
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;

  @media ${DEVICE.tablet} {
    display: block;
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarLogo = styled.div`
  a {
    display: flex;
    align-items: center;
    margin: 27px 23px 51px 24px;
  }

  img {
    width: 76px;
  }
`;

export const SidebarLogoTitle = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: #000;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 14px;
`;

export const SidebarItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarFooter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 40px 24px;
`;

export const SidebarUserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SidebarUserActions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;

  button {
    display: flex;
    align-items: center;
  }

  .ant-btn > .anticon + span {
    margin-left: 0;
  }

  .anticon svg {
    width: 1.5em;
    height: 1.5em;
  }
`;

export const Overlay = styled.div<{ isSidebarOpened: boolean }>`
  display: none;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.4);
  @media (max-width: 992px) {
    display: ${({ isSidebarOpened }): string => (isSidebarOpened ? 'flex' : 'none')};
  }
`;
