import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  authReducer,
  modalReducer,
  sidebarReducer,
  usersReducer,
  schoolsReducer,
  loaderReducer
} from './reducers';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebar: sidebarReducer,
    modal: modalReducer,
    schools: schoolsReducer,
    users: usersReducer,
    loader: loaderReducer
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false
    })
  ]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
