import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';
import { store } from 'services/store';
import { logoutUser } from 'services/store/reducers/authReducer';

class ApiService {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/se_api/v1`
    });

    this.instance.interceptors.request.use(
      (config): AxiosRequestConfig => {
        if (!config.headers.Authorization) {
          const token = localStorage.getItem('accessToken');
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error): Promise<Error> => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response): AxiosResponse => {
        return response;
      },
      async (error): Promise<void | AxiosResponse<any> | Error> => {
        if (error.response) {
          const originalRequest = error.config;
          if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            try {
              originalRequest._retry = true;
              const refreshToken = localStorage.getItem('refreshToken');
              if (!refreshToken) {
                throw new Error(`Refresh token doesn't exist`);
              }
              axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;
              const result = await axios.post(
                `${process.env.REACT_APP_API_URL}/se_api/v1/auth/refresh`
              );
              localStorage.setItem('accessToken', result.data.accessToken);
              localStorage.setItem('refreshToken', result.data.refreshToken);
              return this.instance({
                ...originalRequest,
                headers: {
                  ...originalRequest.headers,
                  Authorization: `Bearer ${result.data.accessToken}`
                }
              });
            } catch (e) {
              store.dispatch(logoutUser());
            }
          }
          return Promise.reject(error.response.data);
        } else if (error.request) {
          return Promise.reject(new Error('Network error'));
        }
        return Promise.reject(error);
      }
    );
  }

  public get<T>(path: string, params?: { [key: string]: any }): Promise<AxiosResponse<T>> {
    return this.instance.get(path, { params });
  }

  public post<T, R>(path: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> {
    return this.instance.post(path, data, config);
  }

  public put<T, R>(path: string, data: T): Promise<Promise<AxiosResponse<R>>> {
    return this.instance.put(path, data);
  }

  public patch<T, R>(
    path: string,
    data: T,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<R>> {
    return this.instance.patch(path, data, config);
  }

  public delete<T, R = AxiosResponse<T>>(path: string, data?: T): Promise<R> {
    return this.instance.delete(path, { data });
  }
}

const apiService = new ApiService();

export default apiService;
