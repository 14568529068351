import { useCallback, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { EResponseHeaders, ESnackbarStyle, VrUser, School } from 'shared/types';
import { getVrUsers } from 'services/api/vrUsersService';
import { openNotification } from 'utils/notification-utils';

type Result = {
  vrUsers: VrUser[];
  fetchVrUsers: () => Promise<void>;
  totalPages: number;
  totalVrUsers: number;
  isVrUsersLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  sortBy: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  schoolId: string;
  setSchoolId: (value: string) => void;
  allSchools: School[];
};

const useVrUsersData = (): Result => {
  const [vrUsers, setVrUsers] = useState<VrUser[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalVrUsers, setTotalVrUsers] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isVrUsersLoading, setIsVrUsersLoading] = useState<boolean>(true);

  const schools = useAppSelector((state): School[] => state.schools.allSchools);
  const [schoolId, setSchoolId] = useState<string>(schools?.length > 1 ? '' : schools[0]?.id || '');

  const fetchVrUsers = useCallback(async (isEditMode?: boolean): Promise<void> => {
    if (!isEditMode) setIsVrUsersLoading(true);
    try {
      const orderBy = { orderBy: '' };
      const response = await getVrUsers(
        { firebaseIdFrom: '0000', firebaseIdTo: '1000' },
        1,
        5000,
        orderBy,
        {}
      );
      setVrUsers(response.data);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalVrUsers(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      if (!isEditMode) setIsVrUsersLoading(false);
    }
  }, []);

  return {
    vrUsers,
    fetchVrUsers,
    totalPages,
    totalVrUsers,
    isVrUsersLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    sortBy,
    setSearchValue,
    searchValue,
    schoolId,
    setSchoolId,
    allSchools: schools
  };
};

export default useVrUsersData;
