import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Admin, CreatedAdmin, UpdatedAdmin } from 'shared/types';

type ChangeAdminPasswordRequest = {
  password: string;
};

export const getAdmins = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string },
  search: { search?: string }
): Promise<AxiosResponse<Admin[]>> =>
  await ApiService.get<Admin[]>('users', { page, limit, ...orderBy, ...search });

export const deleteAdmin = async (id: number): Promise<AxiosResponse<void>> =>
  await ApiService.delete<void>(`users?users=${id}`);

export const addAdmin = async (admin: CreatedAdmin): Promise<AxiosResponse<Admin>> =>
  await ApiService.post<CreatedAdmin, Admin>('users', {
    ...admin
  });

export const updateAdmin = async (id: number, admin: UpdatedAdmin): Promise<AxiosResponse<Admin>> =>
  await ApiService.patch<UpdatedAdmin, Admin>(`users/${id}`, {
    ...admin
  });

export const changeAdminPassword = async (
  id: number,
  password: string
): Promise<AxiosResponse<void>> =>
  await ApiService.patch<ChangeAdminPasswordRequest, void>(`users/${id}/change-password`, {
    password
  });
