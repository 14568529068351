import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';

const NotGuardedRoute: React.FC<RouteProps> = ({ ...routeProps }): JSX.Element => {
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);

  if (isAuth) return <Redirect to='/sessions' />;

  return <Route {...routeProps} />;
};

export default NotGuardedRoute;
