import { ESnackbarStyle } from 'shared/types';
import { notification } from 'antd';
import { DEFAULT_ERROR } from 'shared/constants/errors';

const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const openNotification = (type: ESnackbarStyle, message: string): void => {
  notification[type]({
    message: `${capitalize(type)}!`,
    description: message || DEFAULT_ERROR
  });
};
