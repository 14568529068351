import {
  FilterValue,
  TablePaginationConfig,
  ColumnType,
  SortOrder,
  Key
} from 'antd/lib/table/interface';
import { ESortType, QueryParams } from 'shared/types';

type SorterResult<RecordType> = {
  column?: ColumnType<RecordType> & {
    sortKey?: string;
  };
  order?: SortOrder;
  field?: Key | readonly Key[];
  columnKey?: Key;
};

const getSortValue = (sortKey: string, order: SortOrder): string => {
  const sortType = order === ESortType.ASC ? 'ASC' : 'DESC';
  return `${sortKey},${sortType}`;
};

export const handleSortAction =
  <T>(
    setSortBy: (value: string) => void
  ): ((
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[]
  ) => void) =>
  async (pagination, filters, sorter): Promise<void> => {
    if (Array.isArray(sorter)) {
      const sortParams = sorter
        .reduce((acc: string[], item): string[] => {
          const { column, order } = item;
          if (!!column && !!order) {
            return [...acc, getSortValue(column.sortKey!, order)];
          } else {
            return acc;
          }
        }, [])
        .join(' | ');
      setSortBy(sortParams);
    } else {
      if (sorter.column) {
        setSortBy(getSortValue(sorter.column.sortKey!, sorter.order!));
      } else {
        setSortBy('');
      }
    }
  };

export const getSortQueryParams = (values: { [key: string]: string }): QueryParams =>
  Object.entries(values).reduce(
    (acc, item): QueryParams => (!!item[1] ? { ...acc, [item[0]]: item[1] } : acc),
    {}
  );
