import React, { useRef } from 'react';
import { Button, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState } from 'shared/types';
import ReactHlsPlayer from 'react-hls-player';

type Props = {
  streamUrl: string;
};

const StreamModal: React.FC<Props> = ({ streamUrl }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);
  const playerRef = useRef<HTMLVideoElement | null>(null);

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  return (
    <Modal
      visible={isModalOpened}
      title='Stream'
      onCancel={handleCancel}
      footer={[
        <Button key='close' type={'primary'} onClick={handleCancel} disabled={isModalDataLoading}>
          Close
        </Button>
      ]}
    >
      <ReactHlsPlayer
        playerRef={playerRef}
        src={streamUrl}
        autoPlay={true}
        controls={true}
        width='100%'
        height='100%'
      />
    </Modal>
  );
};

export default StreamModal;
