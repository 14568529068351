import React, { ReactNode, useEffect } from 'react';
import { Container } from './styles';
import { useAppDispatch } from 'shared/hooks';
import { switchLoader } from 'services/store/reducers/loaderReducer';

type Props = {
  children: ReactNode;
};

const FullPageContainer: React.FC<Props> = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect((): void => {
    dispatch(switchLoader(false));
  }, [dispatch]);

  return <Container>{children}</Container>;
};

export default FullPageContainer;
