import React, { useState } from 'react';
import * as Styles from './styles';
import { Button, Input, Modal, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeModal } from 'services/store/reducers/modalReducer';
import { ModalState, InputEvent, TableVrUser, School, VrUser } from 'shared/types';
import { getIn, useFormik } from 'formik';
import { validation } from 'services/validation';
import { getFieldError } from 'utils/error-utils';
import { addNewUser, updateUserItem } from '../../../../services/store/reducers/usersReducer';
import _ from 'lodash';
import { ErrorMessage } from '../../../styles';

type Props = {
  user?: TableVrUser;
  schools: School[];
  vrUsers: VrUser[];
  fetchVrUsers: () => void;
};

const UserActionFormModal: React.FC<Props> = ({
  user,
  fetchVrUsers,
  schools,
  vrUsers
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isModalOpened, isModalDataLoading } = useAppSelector((state): ModalState => state.modal);
  const [domain, setDomain] = useState(
    !!user && user.Email ? '@' + user?.Email?.split('@')?.[1] : ''
  );
  const [initVreduNumber] = useState(
    user?.vreduNumber || (user && /^\d{4}$/.test(user.ID || '') ? user.ID : generateId(vrUsers))
  );
  const domains = _.uniq(
    schools
      .flatMap((school: School) => {
        const domains = typeof school.Domain === 'string' ? [school.Domain] : school.Domain;
        return domains.map((domain) => `${domain}|${school.id}`);
      })
      .filter(Boolean)
      .map((domain) => (/^@/.test(domain) ? domain : `@${domain}`))
  );

  const handleCancel = (): void => {
    dispatch(closeModal());
  };

  const initialValues: TableVrUser = {
    firebaseId: user?.firebaseId,
    FirstName: user?.FirstName || '',
    LastName: user?.LastName || '',
    emailName: user?.Email?.split('@')?.[0] || '',
    Email: user?.Email || '',
    vreduNumber: String(initVreduNumber),
    OrganizationId: user?.OrganizationId || '',
    ID: String(user?.ID) || '',
    key: String(user?.ID) || '',
    Code: user?.Code || ''
  };

  const formik = useFormik({
    onSubmit: async (values): Promise<void> => {
      if (!isValidNewId(vrUsers, getIn(formik.values, 'vreduNumber'), getIn(formik.values, 'ID'))) {
        return;
      }
      await dispatch(
        !!user && user.ID !== undefined
          ? updateUserItem({ userId: user.ID, values })
          : addNewUser({
              values: {
                ...values,
                Code: values.vreduNumber
              }
            })
      );
      dispatch(closeModal());
      fetchVrUsers();
    },
    initialValues,
    validationSchema: !!user ? validation.UPDATE_VR_USER : validation.CREATE_VR_USER,
    enableReinitialize: true
  });

  const handleIdFieldChange = (event: InputEvent): void => {
    const { value, name } = event.target;
    if (name === 'emailName') {
      formik.setFieldValue('Email', value + domain);
    }
    formik.setFieldValue(name, value);
  };

  return (
    <Modal
      visible={isModalOpened}
      title={!!user ? 'Edit User' : 'Add New User'}
      onCancel={handleCancel}
      footer={[
        <Button
          key='save'
          type={'primary'}
          onClick={(): void => formik.handleSubmit()}
          disabled={isModalDataLoading}
        >
          Save
        </Button>
      ]}
    >
      <form onSubmit={formik.handleSubmit}>
        <Styles.InputContainer key={'FirstName'}>
          <Input
            name={'FirstName'}
            value={getIn(formik.values, 'FirstName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'First name'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'FirstName')}
        </Styles.InputContainer>
        <Styles.InputContainer key={'LastName'}>
          <Input
            name={'LastName'}
            value={getIn(formik.values, 'LastName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'Last name'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'LastName')}
        </Styles.InputContainer>
        <Styles.InputContainer key={'emailName'}>
          <Input
            name={'emailName'}
            value={getIn(formik.values, 'emailName')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'Email'}
            disabled={isModalDataLoading}
            allowClear
            style={{ width: '35%' }}
          />
          <Select
            style={{ marginLeft: '23px', width: '60%' }}
            onChange={(value) => {
              const [domain, OrganizationId] = value.split('|');
              setDomain(domain);
              formik.setFieldValue('Email', getIn(formik.values, 'emailName') + domain);
              formik.setFieldValue('OrganizationId', OrganizationId);
            }}
            placeholder={'School domain'}
            disabled={isModalDataLoading}
            value={domain}
          >
            <Select.Option value={''}>{'Select a domain'}</Select.Option>
            {domains.map((domain) => {
              return (
                <Select.Option key={domain} value={domain}>
                  {domain.split('|')[0]}
                </Select.Option>
              );
            })}
          </Select>
          {getFieldError(formik, 'Email')}
        </Styles.InputContainer>

        <Styles.InputContainer key={'vreduNumber'}>
          <Input
            name={'vreduNumber'}
            value={getIn(formik.values, 'vreduNumber')}
            onBlur={formik.handleBlur}
            onChange={handleIdFieldChange}
            placeholder={'CurioXR Number'}
            disabled={isModalDataLoading}
            allowClear
          />
          {getFieldError(formik, 'vreduNumber')}
          {!isValidNewId(
            vrUsers,
            getIn(formik.values, 'vreduNumber'),
            getIn(formik.values, 'ID')
          ) && <ErrorMessage>CurioXR number must be unique!</ErrorMessage>}
        </Styles.InputContainer>

        {!!user && (
          <Styles.InputContainer key={'Code'}>
            <Input
              name={'Code'}
              value={getIn(formik.values, 'Code')}
              onBlur={formik.handleBlur}
              onChange={handleIdFieldChange}
              placeholder={'Code'}
              disabled={isModalDataLoading}
              allowClear
            />
            {getFieldError(formik, 'Code')}
          </Styles.InputContainer>
        )}
      </form>
    </Modal>
  );
};

function isValidNewId(
  vrUsers: { ID: string; vreduNumber: string }[],
  newId: string,
  currentID?: string
): boolean {
  if (currentID === newId) {
    return true;
  }
  const set = new Set(vrUsers.flatMap(({ ID, vreduNumber }) => [ID, vreduNumber]).filter(Boolean));

  return !set.has(newId);
}

function generateId(vrUsers: { ID: string; vreduNumber: string }[]): string {
  let newId = '';
  let searching = true;
  while (searching) {
    newId = String(1000 + Math.ceil(Math.random() * 8999));

    if (isValidNewId(vrUsers, newId)) {
      searching = false;
    }
  }
  return newId;
}

export default UserActionFormModal;
