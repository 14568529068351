import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { AuthState, EAdminRoles } from 'shared/types';

type Props = RouteProps & {
  allowedRoles: EAdminRoles[];
};

const GuardedRoute: React.FC<Props> = ({ allowedRoles, ...routeProps }): JSX.Element => {
  const { isAuth, user } = useAppSelector((state): AuthState => state.auth);

  if (!isAuth) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: routeProps.location }
        }}
      />
    );
  }

  if (!allowedRoles.some((item): boolean => item === user?.role)) {
    return (
      <Redirect
        to={{
          pathname: '/not-found'
        }}
      />
    );
  }

  return <Route {...routeProps} />;
};

export default GuardedRoute;
