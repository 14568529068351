import React, { ReactNode, useEffect } from 'react';
import { Container } from './styles';
import { useAppDispatch } from 'shared/hooks';
import { switchLoader } from 'services/store/reducers/loaderReducer';

type Props = {
  children: ReactNode;
  isDataLoading?: boolean;
};

const PageContainer: React.FC<Props> = ({ children, isDataLoading }): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect((): void => {
    if (!isDataLoading) {
      dispatch(switchLoader(false));
    }
  }, [isDataLoading, dispatch]);

  return <Container>{children}</Container>;
};

export default PageContainer;
