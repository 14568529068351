import { useEffect, useRef, FocusEvent } from 'react';

type Result = {
  handleInputFocus: (event: FocusEvent<HTMLInputElement>) => void;
  handleInputBlur: () => void;
};

const useAutoFocus = (isDataLoading: boolean): Result => {
  const activeInputRef = useRef<HTMLInputElement | null>(null);

  useEffect((): void => {
    if (!isDataLoading && !!activeInputRef.current) {
      activeInputRef.current.focus();
    }
  }, [isDataLoading, activeInputRef]);

  const handleInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
    activeInputRef.current = event.target;
  };

  const handleInputBlur = (): void => {
    activeInputRef.current = null;
  };

  return { handleInputFocus, handleInputBlur };
};

export default useAutoFocus;
