import React from 'react';
import './App.css';
import { store } from 'services/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MainRoutes } from 'routes';

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainRoutes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
