import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import * as Styled from './styles';
import { GuardedRoute, NotGuardedRoute } from 'routes';
import { ERouteType, ModalState, RouteItem } from 'shared/types';
import { ROUTES } from './routes';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Sidebar, Loader } from 'shared/components';
import { MenuOutlined } from '@ant-design/icons';
import { openSidebar } from 'services/store/reducers/sidebarReducer';
import { switchLoader } from 'services/store/reducers/loaderReducer';

const MainRoutes = (): JSX.Element => {
  const isAuth = useAppSelector((state): boolean => state.auth.isAuth);
  const { isModalOpened, modalWindow } = useAppSelector((state): ModalState => state.modal);
  const isLoaderActive = useAppSelector((state): boolean => state.loader.isLoaderActive);
  const dispatch = useAppDispatch();

  const SuspenseLoading = (): JSX.Element => {
    useEffect((): void => {
      dispatch(switchLoader(true));
    }, []);

    return <div />;
  };

  const handleMenuButtonClick = (): void => {
    dispatch(openSidebar());
  };

  const getRoute = (route: RouteItem): JSX.Element => {
    switch (route.type) {
      case ERouteType.GUARDED:
        return (
          <GuardedRoute
            key={route.path}
            exact
            path={route.path}
            component={route.component}
            allowedRoles={route.allowedRoles || []}
          />
        );
      case ERouteType.NOT_GUARDED:
        return (
          <NotGuardedRoute key={route.path} exact path={route.path} component={route.component} />
        );
      default:
        return <Route key={route.path} exact path={route.path} component={route.component} />;
    }
  };

  return (
    <>
      {isLoaderActive && <Loader />}
      <Styled.RootContainer isAuth={isAuth}>
        {isAuth && (
          <>
            <Styled.MenuButton onClick={handleMenuButtonClick}>
              <MenuOutlined style={{ fontSize: '30px' }} />
            </Styled.MenuButton>
            <Sidebar />
          </>
        )}
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {ROUTES.map((item): JSX.Element => getRoute(item))}
            <Route path='*'>
              <Redirect to='/not-found' />
            </Route>
          </Switch>
        </Suspense>
        {isModalOpened && <>{modalWindow}</>}
      </Styled.RootContainer>
    </>
  );
};

export default MainRoutes;
