import { createAction, createReducer } from '@reduxjs/toolkit';
import { LoaderState } from 'shared/types';

const initialState: LoaderState = {
  isLoaderActive: true
};

// Actions

export const switchLoader = createAction<boolean>('loader/switchLoader');

// Reducer

export const loaderReducer = createReducer(initialState, (builder): void => {
  builder.addCase(switchLoader, (state, action): void => {
    state.isLoaderActive = action.payload;
  });
});
