import { useCallback, useState } from 'react';
import { EResponseHeaders, ESnackbarStyle, Admin } from 'shared/types';
import { PAGE_SIZE } from 'shared/constants/pagination';
import { getAdmins } from 'services/api/adminsService';
import { openNotification } from 'utils/notification-utils';
import { useDebounce } from 'shared/hooks';

type Result = {
  admins: Admin[];
  fetchAdmins: () => Promise<void>;
  totalPages: number;
  totalUsers: number;
  isAdminsLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  setSortBy: (value: string) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
};

const useAdminsData = (): Result => {
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [isAdminsLoading, setIsAdminsLoading] = useState<boolean>(true);

  const debouncedValue = useDebounce(searchValue, 1000);

  const fetchAdmins = useCallback(async (): Promise<void> => {
    setIsAdminsLoading(true);
    try {
      const orderBy = sortBy ? { orderBy: sortBy } : {};
      const response = await getAdmins(
        currentPage,
        PAGE_SIZE,
        orderBy,
        debouncedValue ? { search: debouncedValue } : {}
      );
      setAdmins(response.data);
      setTotalPages(+response.headers[EResponseHeaders.TOTAL_PAGES]);
      setTotalUsers(+response.headers[EResponseHeaders.TOTAL_RECORDS]);
      if (+response.headers[EResponseHeaders.TOTAL_RECORDS] <= PAGE_SIZE) setCurrentPage(1);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e.message);
    } finally {
      setIsAdminsLoading(false);
    }
  }, [currentPage, sortBy, debouncedValue]);

  return {
    admins,
    fetchAdmins,
    totalPages,
    totalUsers,
    isAdminsLoading,
    setCurrentPage,
    currentPage,
    setSortBy,
    searchValue,
    setSearchValue
  };
};

export default useAdminsData;
