import { EAdminRoles } from '.';
import { ComponentType, LazyExoticComponent } from 'react';

export enum ERouteType {
  GUARDED = 'GUARDED',
  NOT_GUARDED = 'NOT_GUARDED',
  DEFAULT = 'DEFAULT'
}

export type RouteItem = {
  type: ERouteType;
  path: string;
  component: LazyExoticComponent<ComponentType>;
  allowedRoles?: EAdminRoles[];
};
