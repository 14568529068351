import { TableLog, EAdminRoles } from 'shared/types';

export const LOG_COLUMNS = [
  {
    title: 'Joined At',
    key: 'startedAt',
    ellipsis: true,
    render: (log: TableLog): JSX.Element => <span data-label='Joined At'>{log.startedAt}</span>
  },
  {
    title: 'Finished At',
    key: 'finishedAt',
    ellipsis: true,
    render: (log: TableLog): JSX.Element => <span data-label='Finished At'>{log.finishedAt}</span>
  },
  {
    title: 'Duration',
    key: 'duration',
    ellipsis: true,
    width: 100,
    render: (log: TableLog): JSX.Element => <span data-label='Duration'>{log.duration}</span>
  },
  {
    title: 'Room ID',
    key: 'roomId',
    ellipsis: true,
    width: 100,
    render: (log: TableLog): JSX.Element => <span data-label='Room Id'>{log.roomId}</span>
  },
  {
    title: 'User Role',
    key: 'userRole',
    ellipsis: true,
    width: 100,
    render: (log: TableLog): JSX.Element => <span data-label='User Role'>{log.userRole}</span>
  },
  {
    title: 'School',
    key: 'school',
    ellipsis: true,
    render: (log: TableLog): JSX.Element => <span data-label='School'>{log.school}</span>,
    allowedRoles: [EAdminRoles.SUPER_ADMIN]
  }
];
