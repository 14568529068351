import { Profile, School } from '.';

export type Admin = {
  id: number;
  email: string;
  emailActivated: boolean;
  phoneActivated: boolean;
  createdAt: string;
  profile: Profile;
  role: EAdminRoles;
  school: School;
  timezone: number;
};

export type CreatedAdmin = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  school: School;
  role: EAdminRoles;
};

export type UpdatedAdmin = {
  email: string;
  profile: {
    firstName: string;
    lastName: string;
  };
  school: School;
};

export type TableAdmin = {
  key: number;
  id: number;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  school: School;
  schoolName: string;
  role: EAdminRoles;
};

export enum EAdminRoles {
  SCHOOL_ADMIN = 'SCHOOL_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN'
}
