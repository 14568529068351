import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { CreatedVrUser, ESnackbarStyle, UsersState, VrUserInternal } from 'shared/types';
import { AppDispatch, RootState } from '../index';
import { setIsDataLoadingParameter } from './modalReducer';
import { openNotification } from 'utils/notification-utils';
import { fetchSchools, setSchools } from './schoolsReducer';
import { addVrUser, deleteVrUser, updateVrUser } from '../../api/vrUsersService';
import { getSchools } from '../../api/schoolsService';
import { PAGE_SIZE } from '../../../shared/constants/pagination';

const initialState: UsersState = {
  allUsers: [],
  users: [],
  currentPage: 1,
  totalPages: 0,
  totalSchools: 0,
  isSchoolsLoading: true,
  sortBy: ''
};

// Actions

export const setIsUsersLoading = createAction<boolean>('users/setIsUsersLoading');
export const setCurrentPage = createAction<number>('users/setCurrentPage');
export const setUsers = createAction<UsersState>('users/setSchools');
export const setSortBy = createAction<string>('users/setSortBy');
export const setAllUsers = createAction<VrUserInternal[]>('users/setAllUsers');

// Reducer

export const usersReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(setIsUsersLoading, (state, { payload }): void => {
      state.isSchoolsLoading = payload;
    })
    .addCase(setUsers, (state, { payload }): UsersState => payload)
    .addCase(setCurrentPage, (state, { payload }): void => {
      state.currentPage = payload;
    })
    .addCase(setSortBy, (state, { payload }): void => {
      state.sortBy = payload;
    })
    .addCase(setAllUsers, (state, { payload }): void => {
      state.allUsers = payload;
    });
});

export const fetchUsers = createAsyncThunk<
  Promise<void>,
  void,
  { dispatch: AppDispatch; state: RootState }
>('schools/fetchSchools', async (_, { dispatch, getState }): Promise<void> => {
  dispatch(setIsUsersLoading(true));
  try {
    const schoolsState = getState().schools;
    const response = await getSchools(schoolsState.currentPage, PAGE_SIZE, {
      orderBy: schoolsState.sortBy || 'name,ASC'
    });
    const totalSchools = response.data.length;
    const totalPages = 1;
    dispatch(setSchools({ ...schoolsState, schools: response.data, totalSchools, totalPages }));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsUsersLoading(false));
  }
});

export const updateUserItem = createAsyncThunk<
  Promise<void>,
  { userId: string; values: CreatedVrUser },
  { dispatch: AppDispatch }
>('schools/updateSchoolItem', async ({ userId, values }, { dispatch }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    await updateVrUser(userId, values);
    await dispatch(fetchUsers());
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});

export const addNewUser = createAsyncThunk<
  Promise<void>,
  { values: CreatedVrUser },
  { dispatch: AppDispatch }
>('schools/addNewUser', async ({ values }, { dispatch }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    await addVrUser(values);
    await dispatch(fetchUsers());
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});

export const removeUserItem = createAsyncThunk<
  Promise<void>,
  { userId: string },
  { dispatch: AppDispatch; state: RootState }
>('users/fetchUsers', async ({ userId }, { dispatch, getState }): Promise<void> => {
  dispatch(setIsDataLoadingParameter(true));
  try {
    await deleteVrUser(userId);
    await dispatch(fetchSchools());
    if (getState().users.totalSchools <= PAGE_SIZE) dispatch(setCurrentPage(1));
  } catch (e) {
    openNotification(ESnackbarStyle.ERROR, e.message);
  } finally {
    dispatch(setIsDataLoadingParameter(false));
  }
});
