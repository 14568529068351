import { AxiosResponse } from 'axios';
import ApiService from './api';
import { Log, QueryParams } from 'shared/types';

export const getLogs = async (
  page: number,
  limit: number,
  orderBy: { orderBy?: string },
  queryParams: QueryParams
): Promise<AxiosResponse<Log[]>> =>
  await ApiService.get<Log[]>('logs', { page, limit, ...orderBy, ...queryParams });
