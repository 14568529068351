import { useAppSelector } from 'shared/hooks';
import { Admin, EAdminRoles } from 'shared/types';
import { useCallback } from 'react';

type SchoolParam = { schoolId: string } | {};

type Result = {
  getSchoolParams: () => SchoolParam;
};

const useSchoolParams = (): Result => {
  const currentUser = useAppSelector((state): Admin | null => state.auth.user);

  const getSchoolParams = useCallback(
    (): SchoolParam =>
      currentUser?.role === EAdminRoles.SCHOOL_ADMIN
        ? { schoolId: `${currentUser?.school.id}` }
        : {},
    [currentUser]
  );

  return {
    getSchoolParams
  };
};

export default useSchoolParams;
